<template>
  <div class="HealthCheck" id="HealthCheck">
    <div class="boxs">
      <div class="srbox">
        <p>身高:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Height')" v-model="postData.S_OP_SC_Height" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </div>
      <div class="srbox">
        <p>体重:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Weight')" v-model="postData.S_OP_SC_Weight" placeholder="请输入内容"></el-input>
        <span>kg</span>
      </div>
      <div class="srbox">
        <p>胸围:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Bust')" v-model="postData.S_OP_SC_Bust" placeholder="请输入内容"></el-input>
        <span>cm</span>
      </div>
      <div class="srbox">
        <p>肺活量:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_VitalCapacity')" v-model="postData.S_OP_SC_VitalCapacity" placeholder="请输入内容"></el-input>
      </div>
    </div>

    <div class="boxs">
      <div class="srbox">
        <span style="padding-left: .37rem">是否来月经：</span>
        <span>
          <el-radio v-model="postData.S_OP_SC_IfMenstruation" label="1">有</el-radio>
          <el-radio v-model="postData.S_OP_SC_IfMenstruation" label="0">无</el-radio>
        </span>
      </div>
      <div class="srbox">
        是否首次经期：
        <span>
          <el-radio v-model="postData.S_OP_SC_FirstMenstruation" label="1">有</el-radio>
          <el-radio v-model="postData.S_OP_SC_FirstMenstruation" label="0">无</el-radio>
        </span>
      </div>
      <div class="srbox">
        是否遗精：
        <span>
          <el-radio v-model="postData.S_OP_SC_IfSpermatorrhea" label="1">有</el-radio>
          <el-radio v-model="postData.S_OP_SC_IfSpermatorrhea" label="0">无</el-radio>
        </span>
      </div>
    </div>

    <h3>屈光</h3>
    <div style="text-align: left;width: 80%;padding-left: 12%;">
      是否戴镜：<span>
        <el-radio v-model="postData.S_OP_SC_WearGlasses" label="1">有</el-radio>
        <el-radio v-model="postData.S_OP_SC_WearGlasses" label="0">无</el-radio>
    </span>
    </div>
    <div class="srA">
      <div>
        <ul class="content-sr padding-20">
          <li></li>
          <li>裸眼视力</li>
          <li>矫正视力</li>
          <li>球镜度数</li>
          <li>散光度数</li>
          <li>散光轴位</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @change="zh('S_OP_SC_VAOD')" :class="postData.S_OP_SC_VAOD && (postData.S_OP_SC_VAOD < 0.01|| postData.S_OP_SC_VAOD > 1.5)?'cw':''" v-model="postData.S_OP_SC_VAOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_DVAOD')"  :class="postData.S_OP_SC_DVAOD && (postData.S_OP_SC_DVAOD < 0.01 || postData.S_OP_SC_DVAOD > 1.5)?'cw':''" v-model="postData.S_OP_SC_DVAOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_R_DSOD')"  :class="postData.S_OP_SC_R_DSOD && (postData.S_OP_SC_R_DSOD < -30 || postData.S_OP_SC_R_DSOD > 20)?'cw':''" v-model="postData.S_OP_SC_R_DSOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_R_DCOD')"  :class="postData.S_OP_SC_R_DCOD && (postData.S_OP_SC_R_DCOD < -10 || postData.S_OP_SC_R_DCOD > 9)?'cw':''" v-model="postData.S_OP_SC_R_DCOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_R_AxisOD')"  :class="postData.S_OP_SC_R_AxisOD && (postData.S_OP_SC_R_AxisOD < 0 || postData.S_OP_SC_R_AxisOD > 180)?'cw':''" v-model="postData.S_OP_SC_R_AxisOD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @change="zh('S_OP_SC_VAOS')"  :class="postData.S_OP_SC_VAOS && (postData.S_OP_SC_VAOS < 0.01 || postData.S_OP_SC_VAOS > 1.5)?'cw':''" v-model="postData.S_OP_SC_VAOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_DVAOS')"  :class="postData.S_OP_SC_DVAOS && (postData.S_OP_SC_DVAOS < 0.01 || postData.S_OP_SC_DVAOS > 1.5)?'cw':''" v-model="postData.S_OP_SC_DVAOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_R_DSOS')"  :class="postData.S_OP_SC_R_DSOS && (postData.S_OP_SC_R_DSOS < -30 || postData.S_OP_SC_R_DSOS > 20)?'cw':''" v-model="postData.S_OP_SC_R_DSOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_R_DCOS')"  :class="postData.S_OP_SC_R_DCOS && (postData.S_OP_SC_R_DCOS < -10 || postData.S_OP_SC_R_DCOS > 9)?'cw':''" v-model="postData.S_OP_SC_R_DCOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_R_AxisOS')"  :class="postData.S_OP_SC_R_AxisOS && (postData.S_OP_SC_R_AxisOS < 0 || postData.S_OP_SC_R_AxisOS > 180)?'cw':''" v-model="postData.S_OP_SC_R_AxisOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>
    <div class="srA" style="padding-top: 0">
      <div>
        <ul class="content-sr">
          <li></li>
          <li>眼位</li>
          <li>串镜</li>
          <li>眼轴</li>
          <li>沙眼</li>
          <li>眼病</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @change="zh('S_OP_SC_PositionOD')"  v-model="postData.S_OP_SC_PositionOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_CJOD')"  v-model="postData.S_OP_SC_CJOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_AxisOculOD')"  :class="postData.S_OP_SC_AxisOculOD && (postData.S_OP_SC_AxisOculOD < 10 || postData.S_OP_SC_AxisOculOD > 10)?'cw':''" v-model="postData.S_OP_SC_AxisOculOD" placeholder="请输入内容"></el-input>mm</li>
          <li><el-input @change="zh('S_OP_SC_TrachomaOD')"  v-model="postData.S_OP_SC_TrachomaOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('EyeDiseaseOD')"  v-model="postData.EyeDiseaseOD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @change="zh('S_OP_SC_PositionOS')"  v-model="postData.S_OP_SC_PositionOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_CJOS')"  v-model="postData.S_OP_SC_CJOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_AxisOculOS')"  :class="postData.S_OP_SC_AxisOculOS && (postData.S_OP_SC_AxisOculOS < 10 || postData.S_OP_SC_AxisOculOS > 40)?'cw':''" v-model="postData.S_OP_SC_AxisOculOS" placeholder="请输入内容"></el-input>mm</li>
          <li><el-input @change="zh('S_OP_SC_TrachomaOS')"  v-model="postData.S_OP_SC_TrachomaOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('EyeDiseaseOS')"  v-model="postData.EyeDiseaseOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>
    <h3>眼生物测量仪</h3>
    <div class="srA">
      <div>
        <ul class="content-sr padding-20">
          <li></li>
          <li>眼轴长度</li>
          <li>中央角膜厚度</li>
          <li>晶体厚度</li>
          <li>平坦K前</li>
          <li>平坦K后</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @change="zh('S_OP_SC_AxisOculOD')"  v-model="postData.S_OP_SC_AxisOculOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_CCTOD')"  v-model="postData.S_OP_SC_CCTOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_LTOD')"  v-model="postData.S_OP_SC_LTOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_FlatK1OD')"  v-model="postData.S_OP_SC_FlatK1OD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_FlatK2OD')"  v-model="postData.S_OP_SC_FlatK2OD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @change="zh('S_OP_SC_AxisOculOS')"  v-model="postData.S_OP_SC_AxisOculOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_CCTOS')"  v-model="postData.S_OP_SC_CCTOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_LTOS')"  v-model="postData.S_OP_SC_LTOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_FlatK1OS')"  v-model="postData.S_OP_SC_FlatK1OS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_FlatK2OS')"  v-model="postData.S_OP_SC_FlatK2OS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>
    <div class="srA" style="padding-top: 0">
      <div>
        <ul class="content-sr">
          <li></li>
          <li>陡峭K前</li>
          <li>陡峭K后</li>
          <li>瞳孔</li>
          <li>白到白</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @change="zh('S_OP_SC_SteepK1OD')"  v-model="postData.S_OP_SC_SteepK1OD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_SteepK2OD')"  v-model="postData.S_OP_SC_SteepK2OD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_PupilOD')"  v-model="postData.S_OP_SC_PupilOD" placeholder="请输入内容"></el-input>mm</li>
          <li><el-input @change="zh('S_OP_SC_WTWOD')"  v-model="postData.S_OP_SC_WTWOD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @change="zh('S_OP_SC_SteepK1OS')"  v-model="postData.S_OP_SC_SteepK1OS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_SteepK2OS')"  v-model="postData.S_OP_SC_SteepK2OS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_SC_PupilOS')"  v-model="postData.S_OP_SC_PupilOS" placeholder="请输入内容"></el-input>mm</li>
          <li><el-input @change="zh('S_OP_SC_WTWOS')"  v-model="postData.S_OP_SC_WTWOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>
    <div class="boxs">
      <div class="srbox">
        <p>牙龈:</p>
        <el-input @change="zh('S_OP_SC_Gums')" class="inpsr" v-model="postData.S_OP_SC_Gums" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>乳龋患:</p>
        <el-input  @change="zh('S_OP_SC_ECCDisease')" class="inpsr" v-model="postData.S_OP_SC_ECCDisease" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>乳龋失:</p>
        <el-input  @change="zh('S_OP_SC_ECCMiss')" class="inpsr" v-model="postData.S_OP_SC_ECCMiss" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>乳龋补:</p>
        <el-input @change="zh('S_OP_SC_ECCRepair')" class="inpsr" v-model="postData.S_OP_SC_ECCRepair" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>恒龋患:</p>
        <el-input @change="zh('S_OP_SC_PermanentToothDisease')" class="inpsr" v-model="postData.S_OP_SC_PermanentToothDisease" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>恒龋失:</p>
        <el-input @change="zh('S_OP_SC_PermanentToothMiss')" class="inpsr" v-model="postData.S_OP_SC_PermanentToothMiss" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>恒龋补:</p>
        <el-input @change="zh('S_OP_SC_PermanentToothRepair')" class="inpsr" v-model="postData.S_OP_SC_PermanentToothRepair" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>牙周疾病:</p>
        <el-input @change="zh('S_OP_SC_PeriodontalDisease')" class="inpsr" v-model="postData.S_OP_SC_PeriodontalDisease" placeholder="请输入内容"></el-input>
      </div>


      <div class="srbox">
        <p>舒张压:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_SystolicPressure')" v-model="postData.S_OP_SC_SystolicPressure" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>收缩压:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_DiastolicPressure')" v-model="postData.S_OP_SC_DiastolicPressure" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>心脏:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Heart')" v-model="postData.S_OP_SC_Heart" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>肺:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Lungs')" v-model="postData.S_OP_SC_Lungs" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>肝:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Liver')" v-model="postData.S_OP_SC_Liver" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>肾:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Kidney')" v-model="postData.S_OP_SC_Kidney" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>脾:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Spleen')" v-model="postData.S_OP_SC_Spleen" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>头部:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Head')" v-model="postData.S_OP_SC_Head" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>颈部:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Neck')" v-model="postData.S_OP_SC_Neck" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>胸部:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Chest')" v-model="postData.S_OP_SC_Chest" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>淋巴结:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_LymphGland')" v-model="postData.S_OP_SC_LymphGland" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>甲状腺:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Thyroid')" v-model="postData.S_OP_SC_Thyroid" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>糖尿病:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_DiabetesMellitus')" v-model="postData.S_OP_SC_DiabetesMellitus" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>腹部检查	:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Abdominal')" v-model="postData.S_OP_SC_Abdominal" placeholder="请输入内容"></el-input>
      </div>

      <div class="srbox">
        <p>脊柱侧弯总述:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Scoliosis')" v-model="postData.S_OP_SC_Scoliosis" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>胸段:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_SpinalCurvature_B')" v-model="postData.S_OP_SC_SpinalCurvature_B" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>腰胸段:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_SpinalCurvature_BW')" v-model="postData.S_OP_SC_SpinalCurvature_BW" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>腰段:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_SpinalCurvature_W')" v-model="postData.S_OP_SC_SpinalCurvature_W " placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>前后:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_SpinalCurvature_FB')" v-model="postData.S_OP_SC_SpinalCurvature_FB" placeholder="请输入内容"></el-input>
      </div>

      <div class="srbox">
        <p>四肢:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Limb')" v-model="postData.S_OP_SC_Limb" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>平足:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Flatfoot')" v-model="postData.S_OP_SC_Flatfoot" placeholder="请输入内容"></el-input>
      </div>


      <div class="srbox">
        <p>辨色:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_ColorDiscrimination')" v-model="postData.S_OP_SC_ColorDiscrimination" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>结膜炎:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Conjunctivitis')" v-model="postData.S_OP_SC_Conjunctivitis" placeholder="请输入内容"></el-input>
      </div>


      <div class="srbox">
        <p>耳病:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Ear')" v-model="postData.S_OP_SC_Ear" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>鼻病:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Nose')" v-model="postData.S_OP_SC_Nose" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>扁桃体:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Tonsil')" v-model="postData.S_OP_SC_Tonsil" placeholder="请输入内容"></el-input>
      </div>


      <div class="srbox">
        <p>发育:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Development')" v-model="postData.S_OP_SC_Development" placeholder="请输入内容"></el-input>
      </div>
      <div class="srbox">
        <p>营养:</p>
        <el-input class="inpsr" @change="zh('S_OP_SC_Nutrition')" v-model="postData.S_OP_SC_Nutrition" placeholder="请输入内容"></el-input>
      </div>


    </div>

    <div class="jy">
      <p>建议:</p>
      <div style="width: 66vw;border: 1px solid #cecdcd;padding: 1.5vw;border-radius: 5px;">
        <p style="width: 100%;text-align: left!important;">{{postData.S_OP_SC_SuggestionOD}}</p>
        <p style="width: 100%;text-align: left!important;">{{postData.S_OP_SC_SuggestionOS}}</p>
      </div>
    </div>

    <div class="scimg">
      <el-upload
          action="#"
          list-type="picture-card"
          accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"
          :file-list="fileLists"
          ref="uptqs"
          :on-change="scp"
          :auto-upload="false">
        <!--       <i slot="default" class="el-icon-plus"></i>-->
        <div>上传图片</div>
        <div slot="file" slot-scope="{file}">
          <img
              class="el-upload-list__item-thumbnail"
              :src="file.url" alt=""
          >
          <span class="el-upload-list__item-actions">
          <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </div>
      </el-upload>
    </div>

    <div class="bc">
      <el-button>返回</el-button>
      <el-button type="primary" @click="saves">保存</el-button>
    </div>
  </div>
</template>

<script>
import pf from "@/publicFn/baseFn.js"
export default {
  name: "HealthCheck",
  data() {
    return {
      datas: '',
      textarea2: '',
      fileLists: [],
      disabled: false,
      dialogVisible: false,
      dialogImageUrl: '',
      postData: {
        S_OP_SC_DiabetesMellitus: '',
        S_OP_SC_SpinalCurvature_B: '',
        S_OP_SC_SpinalCurvature_BW: '',
        S_OP_SC_SpinalCurvature_W: '',
        S_OP_SC_SpinalCurvature_FB: '',
        S_OP_SC_IfMenstruation: '',
        S_OP_SC_FirstMenstruation: '',
        S_OP_SC_IfSpermatorrhea: '',
        S_OP_SC_Kidney:'',
        S_OP_SC_WearGlasses: '',
        S_OP_SC_FlatK1OD: '',
        S_OP_SC_FlatK1OS: '',
        S_OP_SC_FlatK2OD: '',
        S_OP_SC_FlatK2OS: '',
        S_OP_SC_SteepK1OD: '',
        S_OP_SC_SteepK1OS: '',
        S_OP_SC_SteepK2OD: '',
        S_OP_SC_SteepK2OS: '',
        S_OP_SC_CJOD: '',
        S_OP_SC_CJOS: '',
        EyeDiseaseOD: '',
        EyeDiseaseOS: '',
        S_OP_SC_DVAOD: '',
        S_OP_SC_DVAOS: '',
        S_OP_Confirm_ExpertId: "",
        S_OP_Confirm_Time: "",
        S_OP_SC_ADOD: "",
        S_OP_SC_ADOS: "",
        S_OP_SC_ALOD: "",
        S_OP_SC_ALOS: "",
        S_OP_SC_ASTOD: "",
        S_OP_SC_ASTOS: "",
        S_OP_SC_AXSOD: "",
        S_OP_SC_AXSOS: "",
        S_OP_SC_Abdominal: "",
        S_OP_SC_Age: '',
        S_OP_SC_Anisometropia: "",
        S_OP_SC_AxisOculOD: "",
        S_OP_SC_AxisOculOS: "",
        S_OP_SC_BMI: "",
        S_OP_SC_Bust: "",
        S_OP_SC_CCTOD: "",
        S_OP_SC_CCTOS: "",
        S_OP_SC_CYLOD: "",
        S_OP_SC_CYLOS: "",
        S_OP_SC_Chest: "",
        S_OP_SC_ColorDiscrimination: "",
        S_OP_SC_Conjunctivitis: "",
        S_OP_SC_CorrectedVA_OD: "",
        S_OP_SC_CorrectedVA_OS: "",
        S_OP_SC_Development: "",
        S_OP_SC_DiastolicPressure: "",
        S_OP_SC_ECCDisease: "",
        S_OP_SC_ECCMiss: "",
        S_OP_SC_ECCRepair: "",
        S_OP_SC_Ear: "",
        S_OP_SC_EyeDisease: "",
        S_OP_SC_FixationAsymmetry: "",
        S_OP_SC_Flatfoot: "",
        S_OP_SC_Gender: "",
        S_OP_SC_Gums: "",
        S_OP_SC_Head: "",
        S_OP_SC_Heart: "",
        S_OP_SC_Height: "",
        S_OP_SC_IOPOD: "",
        S_OP_SC_IOPOS: "",
        S_OP_SC_K1OD: "",
        S_OP_SC_K1OS: "",
        S_OP_SC_K2OD: "",
        S_OP_SC_K2OS: "",
        S_OP_SC_LTOD: "",
        S_OP_SC_LTOS: "",
        S_OP_SC_Limb: "",
        S_OP_SC_Liver: "",
        S_OP_SC_Lungs: "",
        S_OP_SC_LymphGland: "",
        S_OP_SC_Neck: "",
        S_OP_SC_Nose: "",
        S_OP_SC_Nutrition: "",
        S_OP_SC_PD: "",
        S_OP_SC_PeriodontalDisease: "",
        S_OP_SC_PermanentToothDisease: "",
        S_OP_SC_PermanentToothMiss: "",
        S_OP_SC_PermanentToothRepair: "",
        S_OP_SC_PositionOD: "",
        S_OP_SC_PositionOS: "",
        S_OP_SC_PupilDifferent: "",
        S_OP_SC_PupilOD: "",
        S_OP_SC_PupilOS: "",
        S_OP_SC_R_AxisOD: "",
        S_OP_SC_R_AxisOS: "",
        S_OP_SC_R_CYLOD: "",
        S_OP_SC_R_CYLOS: "",
        S_OP_SC_R_DCOD: "",
        S_OP_SC_R_DCOS: "",
        S_OP_SC_R_DSOD: "",
        S_OP_SC_R_DSOS: "",
        S_OP_SC_R_FarOD: "",
        S_OP_SC_R_FarOS: "",
        S_OP_SC_R_NearOD: "",
        S_OP_SC_R_NearOS: "",
        S_OP_SC_Remark: "",
        S_OP_SC_SCOD: "",
        S_OP_SC_SCOS: "",
        S_OP_SC_SEOD: "",
        S_OP_SC_SEOS: "",
        S_OP_SC_SPHOD: "",
        S_OP_SC_SPHOS: "",
        S_OP_SC_Scoliosis: "",
        S_OP_SC_Skin: "",
        S_OP_SC_Spleen: "",
        S_OP_SC_SuggestionOD: "",
        S_OP_SC_SuggestionOS: "",
        S_OP_SC_SystolicPressure: "",
        S_OP_SC_Thyroid: "",
        S_OP_SC_Tonsil: "",
        S_OP_SC_TrachomaOD: "",
        S_OP_SC_TrachomaOS: "",
        S_OP_SC_VAOD: "",
        S_OP_SC_VAOS: "",
        S_OP_SC_VTOD: "",
        S_OP_SC_VTOS: "",
        S_OP_SC_VitalCapacity: "",
        S_OP_SC_WTWOD: "",
        S_OP_SC_WTWOS: "",
        S_OP_SC_Weight: "",
        S_OP_SC_date1: "",
        S_OP_SC_date2: "",
        S_OP_SC_date3: "",
        S_OP_SC_date4: "",
        S_OP_SC_date5: ""
      },
      ids: ''
    }
  },
  created() {
    if (this.$route.query.id) {
      this.ids = this.$route.query.id
      this._api.publicApi.getImgList('HealthCheck', this.ids)
          .then(res => {
            let arr = []
            if (res.GetListResult && res.GetListResult.length > 0) {
              for (let i = 0; i < res.GetListResult.length; i++) {
                arr.push({
                  name: res.GetListResult[i].UFId,
                  url: res.GetListResult[i].FullURL,
                })
              }
              this.fileLists = arr
            }
          })

      this._api.publicApi.getHealthCheck(this.$route.query.id)
      .then(res => {
        if (res.GetResult) {
          if (res.GetResult.ListS_OP_All && res.GetResult.ListS_OP_All.length) {
            this.datas = res.GetResult.ListS_OP_All[0]
            if (res.GetResult.ListS_OP_All[0].S_OP_Json) {
               this.postData = pf.decoding(res.GetResult.ListS_OP_All[0].S_OP_Json)
             }
          }
        }
      })
    }

  },
  methods: {
    saves() {
      let obj = document.getElementById('HealthCheck'),
          arr = obj.getElementsByClassName('cw')
      if (arr.length > 0) {
        this.$confirm('请复核红色区域数据是否异常！', '提示', {
          confirmButtonText: '继续提交',
          cancelButtonText: '取消',
          type: 'warning'})
        .then(res => {
          this.tj()
        })
      }else {
        this.tj()
      }
    },

    tj() {
      this.datas.S_OP_Json = pf.conversionJson(this.postData)
      let loading = this.$loading({
        lock: true,
        text: '保存中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this._http.post(this._getUrl('S_ALL_Normal_Save'), {
        "token": {
          "TimeSpan": 1566827733,
          "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: this.datas
      })
          .then(res => {
            loading.close()
            if (res.UpdateResult) {
              this.$alert('保存成功!', '提示')
            }else {
              this.$alert('保存失败!', '提示')
            }
          })
    },

    scp(file) {
      let _this = this
      let reader = new FileReader(), imgBase64 = '';
      reader.readAsDataURL(file.raw)
      reader.onload = function (e) {
        _this._http.post(pf.getUrl('UploadFile_Upload'), {
          "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
          },
          o: {
            FileBase64: e.currentTarget.result.split(',')[1],
            ExtensionName: ".png",
            UFSaveType: 'OSSPrivate',
            UFReObject: "HealthCheck",
            UFType: 'HealthCheck',
            UFReId: _this.ids
          }
        })
            .then(res => {
              if (res.Id) {
                file.name = res.Id
              }
            })
      }
    },

    handleRemove(file) {
      this._api.publicApi.deleImg(file.name)
          .then(res => {
            if (res.DeleteByIdReturn) {
              let upwj = this.$refs.uptqs.uploadFiles
              for (let i = 0; i < upwj.length; i++) {
                if (upwj[i]['url'] == file.url) {
                  upwj.splice(i, 1)
                }
              }
            }
          })
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      // console.log(file);
    },

    zh(key) {
      this.postData[key] = this.postData[key]?this.postData[key].toString():''
    }
  }
}
</script>

<style scoped lang="scss">
  .HealthCheck {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
  .boxs {
    width: 80%;
    padding: 0 10%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 20px;
    .srbox {
      display: flex;
      justify-content: start;
      align-items: center;
      padding-bottom: 20px;
      .inpsr {width: 8vw; margin-left: 10px;}
      p {width: 5em;text-align: right}
      margin-right: .3rem;
    }
  }
  .srA {
    width: 89%;
    padding-left: 11%;
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: start;
    .content-sr {
      display: flex;
      justify-content: start;
      align-items: center;
      li {
        width: 8vw;
        margin-right: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #000000;
          border-radius: 5px;
          padding: 5px 0;
          text-align: center;
        }
      }
      li:nth-child(1) {
        width: 3vw;
        text-align: left;
        margin-right: 5px;
      }
    }
    .tj {
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
        margin-top: 21px;
      }
    }
    .last {
      margin-left: 50px;
      margin-top: 32px;
    }
  }
  .padding-20{padding-top: 15px;}
  .jy {
    width: 80%;
    padding: 0 10%;
    display: flex;
    .jysr {width: 60vw}
    p {width: 5em; text-align: right; padding-right: 10px;}
  }
  .bc {
    padding: 40px 0;
  }

  .scimg {
    width: 80%;
    margin: 0 auto;
    text-align: left;
    padding-left:12%;
    margin-top: 20px;
  }
  .cw {background: #ef4674;}
  ::v-deep .cw  .el-input__inner{background: #ef4674 !important;}
</style>
